import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
} from '../../components';
import { TopbarContainer } from '..';
import ListingPage from '../ListingPage/ListingPage';

import css from './ListingPageVanity.module.css';

export const ListingPageVanityComponent = props => {
  const {
    intl,
    scrollingDisabled,
    params,
    getListingInProgress,
    getListingError,
    vanityId,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ListingPageVanity.schemaTitle' }, { siteTitle });

  if (getListingError) {
    // Fetching listing error
    return <NamedRedirect name="NotFoundPage" />;
  } else if (getListingInProgress) {
    // Fetching listing in progress
    return (
      <Page className={css.root} scrollingDisabled={scrollingDisabled} title={schemaTitle}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}>
              <FormattedMessage id="ListingPageVanity.loading" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (vanityId) {
    // vanityId found, redirect to ListingPage
    return <ListingPage params={{ id: vanityId.uuid, slug: params.slug }} vanity />;
  } else {
    // Lastly, there's no vanityId redirect to NotFoundPage
    return <NamedRedirect name="NotFoundPage" />;
  }
};

ListingPageVanityComponent.propTypes = {
  getListingInProgress: false,
  getListingError: null,
  vanityId: null,
};

ListingPageVanityComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  getListingInProgress: bool.isRequired,
  getListingError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { getListingInProgress, getListingError, vanityId } = state.ListingPageVanity;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    getListingInProgress,
    getListingError,
    vanityId,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPageVanity = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageVanityComponent);

export default ListingPageVanity;
